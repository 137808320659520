
import { Component, Vue } from 'vue-property-decorator'
import { TableData } from '@/types/dispatch'
import TypeDialog from './TypeDialog.vue'
import { KeepAlive } from '@/utils/decorators'
@Component({
  name: 'dispatchList',
  components: { TypeDialog },
  filters: {
    dispatchState: (value: string) => {
      switch (value) {
        case '1':
          return '待核实'
        case '2':
          return '处置中'
        case '3':
          return '已完成'
        case '4':
          return '核实不通过'
        case '5':
          return '已取消'
        default: return ''
      }
    }
  }
})
@KeepAlive
export default class extends Vue {
  private searchInfo = {
    typeId: '',
    projectId: '',
    dispatchGrade: '',
    dispatchState: ''
  }

  private dataRange = []

  private tableData: TableData[] =[]
  private typeList = []
  private gradeList = []

  private page = 1
  private total = 0
  private size = 10
  private loading=false
  private isShowImport = false
  private importUrl = ''
  private showDialog = false

  get projectList () {
    return this.$store.state.projectList
  }

  created () {
    this.getData()
    this.getTypeList()
    this.getGradeList()
  }

  getData () {
    this.loading = true
    const info = this.dataRange ? {
      startTime: this.dataRange[0],
      endTime: this.dataRange[1],
      ...this.searchInfo
    } : {
      ...this.searchInfo
    }
    this.$axios.get(this.$apis.dispatch.selectDispatchByPage, {
      page: this.page,
      size: this.size,
      ...info
    }).then(res => {
      if (res) {
        this.total = res.total || 0
        this.tableData = res.list || []
      }
    }).finally(() => {
      this.loading = false
    })
  }

  // 事件类型
  getTypeList () {
    this.$axios.get(this.$apis.dispatch.selectDispatchTypeByList).then(res => {
      this.typeList = res.list || []
    })
  }

  // 事件等级
  getGradeList () {
    this.$axios.get(this.$apis.common.selectDicByList, {
      dicType: 'dispatchGrade'
    }).then(res => {
      this.gradeList = res.dispatchGrade || []
    })
  }

  onAdd () {
    this.$router.push({ name: 'dispatchAdd' })
  }

  // 类型配置关闭
  closeDialog () {
    this.showDialog = false
    this.searchData()
    this.getTypeList()
  }

  // 核实
  onVerify (id: string) {
    this.$router.push({ name: 'dispatchVerify', params: { id } })
  }

  // 处置
  onDispose (id: string) {
    this.$router.push({ name: 'dispatchDispose', params: { id } })
  }

  onDetail (id: string) {
    this.$router.push({ name: 'dispatchDetail', params: { id } })
  }

  // 导入
  onImport () {
    this.importUrl = this.$apis.manholeManage.importManhole
    this.isShowImport = true
  }

  searchData () {
    this.page = 1
    this.getData()
  }

  onUpdate (id: string) {
    this.$router.push({ name: 'manholeManageUpdate', params: { id: id } })
  }

  onCancel (id: string) {
    this.$confirm('确认取消吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.$axios
          .post(this.$apis.dispatch.cancelDispatch, { dispatchId: id })
          .then(() => {
            this.$message.success('取消成功')
            this.searchData()
          })
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
