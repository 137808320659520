
import { Component, Vue, Prop } from 'vue-property-decorator'
import { TypeItem } from '@/types/dispatch'

@Component({
  name: 'TypeDialog'
})
export default class extends Vue {
  @Prop() readonly showDialog!: boolean

  private loading = false
  private tableData: TypeItem[] = []

  private oldData: { [key: number]: TypeItem } = {}
  private isAddType: number | null = null

  created () {
    this.getTypeList()
  }

  getTypeList () {
    this.loading = true
    this.$axios.get(this.$apis.dispatch.selectDispatchTypeByList).then(res => {
      if (res) {
        this.tableData = res.list || []
      }
    }).finally(() => {
      this.loading = false
    })
  }

  onAdd () {
    if (this.isAddType != null) {
      this.$message.warning('请先保存！')
    } else {
      this.tableData.unshift({
        typeName: '',
        changeType: '1',
        isInput: true
      })
      this.isAddType = this.tableData.length - 1
    }
  }

  onEdit (row: TypeItem, index: number) {
    if (this.isAddType != null) {
      this.$message.warning('请先保存！')
    } else {
      this.tableData[index] = {
        ...row,
        isInput: true
      }
      this.oldData[index] = row
      this.isAddType = index
      this.$set(this.tableData, index, this.tableData[index]) // 重新渲染表格数据
    }
  }

  onDelete (typeId: string) {
    if (this.isAddType != null) {
      this.$message.warning('请先保存！')
    } else {
      this.$confirm('确认删除吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        this.$axios.post(this.$apis.dispatch.deleteDataDispatchType, { typeId: typeId }).then(() => {
          this.$message.success('删除成功')
          this.isAddType = null
          this.getTypeList()
        }).finally(() => {
          this.loading = false
        })
      })
    }
  }

  onSave (row: TypeItem) {
    if (row.typeName) {
      const info = { typeName: row.typeName, changeType: '1' }
      const url = row.typeId ? this.$apis.dispatch.updateDispatchType : this.$apis.dispatch.insertDispatchType
      this.$axios.post(url, row.typeId ? { ...info, typeId: row.typeId } : info).then(() => {
        this.$message({
          message: '保存成功',
          type: 'success'
        })
        this.isAddType = null
        this.getTypeList()
      })
    } else {
      this.$message({
        message: '请输入类型名称',
        type: 'warning'
      })
    }
  }

  onCancel (row: TypeItem, index: number) {
    this.isAddType = null
    if (row.typeId) {
      // 是否为编辑
      this.tableData[index].isInput = false
      this.tableData[index].typeName = this.oldData[index].typeName // 取消值不变
    } else {
      this.tableData.splice(index, 1)
    }
  }

  closeDialog () {
    this.$emit('closeDialog')
  }
}
